import React, { useState, useEffect } from "react";
import DashboardHeader from './DashboardHeader'
import { Link, useLocation } from 'react-router-dom'
import { OM_API_URL } from '../BaseURL';
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements
} from '@stripe/react-stripe-js';

import CheckoutForm from "./Checkout";

const stripePromise = loadStripe("pk_test_51MBjiMFiqjYMsHSsuj99rR6KYfGqeBMf5L1G6pfkBzg4azDn8g3OHVTNw6hPJFm0LziAsjJNdI2upNe4azgcpp35003evaVBvQ");



const Payment = () => {
    const location = useLocation();


    return (
        <div>
            <div className="rd-dashboard-content">

                <DashboardHeader />
                <main className="rd-site-content">
                    <div className="rd-site-content__inner">
                        <div className="rd-page-head">
                            <button className="navbar-toggler" type="button">
                                <span className="navbar-toggler-icon"></span>
                                <span className="navbar-toggler-icon"></span>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_397_15659" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                                    <rect width="36" height="36" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_397_15659)">
                                    <path d="M13.5 30C12.675 30 11.969 29.7065 11.382 29.1195C10.794 28.5315 10.5 27.825 10.5 27C10.5 26.175 10.794 25.4685 11.382 24.8805C11.969 24.2935 12.675 24 13.5 24C14.325 24 15.0315 24.2935 15.6195 24.8805C16.2065 25.4685 16.5 26.175 16.5 27C16.5 27.825 16.2065 28.5315 15.6195 29.1195C15.0315 29.7065 14.325 30 13.5 30ZM22.5 30C21.675 30 20.969 29.7065 20.382 29.1195C19.794 28.5315 19.5 27.825 19.5 27C19.5 26.175 19.794 25.4685 20.382 24.8805C20.969 24.2935 21.675 24 22.5 24C23.325 24 24.0315 24.2935 24.6195 24.8805C25.2065 25.4685 25.5 26.175 25.5 27C25.5 27.825 25.2065 28.5315 24.6195 29.1195C24.0315 29.7065 23.325 30 22.5 30ZM13.5 21C12.675 21 11.969 20.706 11.382 20.118C10.794 19.531 10.5 18.825 10.5 18C10.5 17.175 10.794 16.4685 11.382 15.8805C11.969 15.2935 12.675 15 13.5 15C14.325 15 15.0315 15.2935 15.6195 15.8805C16.2065 16.4685 16.5 17.175 16.5 18C16.5 18.825 16.2065 19.531 15.6195 20.118C15.0315 20.706 14.325 21 13.5 21ZM22.5 21C21.675 21 20.969 20.706 20.382 20.118C19.794 19.531 19.5 18.825 19.5 18C19.5 17.175 19.794 16.4685 20.382 15.8805C20.969 15.2935 21.675 15 22.5 15C23.325 15 24.0315 15.2935 24.6195 15.8805C25.2065 16.4685 25.5 17.175 25.5 18C25.5 18.825 25.2065 19.531 24.6195 20.118C24.0315 20.706 23.325 21 22.5 21ZM13.5 12C12.675 12 11.969 11.706 11.382 11.118C10.794 10.531 10.5 9.825 10.5 9C10.5 8.175 10.794 7.469 11.382 6.882C11.969 6.294 12.675 6 13.5 6C14.325 6 15.0315 6.294 15.6195 6.882C16.2065 7.469 16.5 8.175 16.5 9C16.5 9.825 16.2065 10.531 15.6195 11.118C15.0315 11.706 14.325 12 13.5 12ZM22.5 12C21.675 12 20.969 11.706 20.382 11.118C19.794 10.531 19.5 9.825 19.5 9C19.5 8.175 19.794 7.469 20.382 6.882C20.969 6.294 21.675 6 22.5 6C23.325 6 24.0315 6.294 24.6195 6.882C25.2065 7.469 25.5 8.175 25.5 9C25.5 9.825 25.2065 10.531 24.6195 11.118C24.0315 11.706 23.325 12 22.5 12Z" fill="black" />
                                </g>
                            </svg>
                            <h1 className="rd-dashboard-logged-name">Good Afternoon</h1>
                        </div>
                        <div className="rd-page-content">
                            <div className="rd-add-customer-content payment-content">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className="rd-content-area">
                                            <div className="App">
                                                {/* {clientSecret && ( */}
                                                <Elements stripe={stripePromise}>
                                                    <CheckoutForm plan={location.state} />
                                                </Elements>
                                                {/* )} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Payment