import React from 'react'
import { Link } from 'react-router-dom'

const SignupTwo = () => {
    return (
        <div>

            <main className="rd-site-content">
                <section className="rd-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="rd-login-content om-signup-content">
                                <Link to="#" className="rd-login-logo">
                                    <span className="rd-login-logo__img">
                                        <img src="assets/images/logo.png" alt="logo" className="img-fluid" />
                                    </span>
                                </Link>
                                <div className="rd-login-form-content" data-scrollbar style={{ overflow: "auto" }}>
                                    <div className="rd-login-form-heading">
                                        <h1 className="rd-login-form-title">Sign-Up</h1>
                                        <p className="rd-login-form-description">Business Details</p>
                                    </div>
                                    <div className="rd-login-form">
                                        <form className="row g-0" autocomplete="off">
                                            <div className="col-md-12">
                                                <label for="inputUsername" className="form-label">Business Name</label>
                                                <input type="text" className="form-control" id="inputUsername" />
                                            </div>
                                            <div className="col-md-12">
                                                <label for="inputPassword4" className="form-label">Business Email</label>
                                                <input type="text" className="form-control" id="inputPassword4" />
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                                <label for="inputPassword4" className="form-label">Total Users</label>
                                                <input type="text" className="form-control" id="inputPassword4" />
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                                <label for="inputPassword4" className="form-label">Business Type</label>
                                                <select className="form-select form-control">
                                                    <option>School</option>
                                                    <option>NGO</option>
                                                    <option>Startups</option>
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <div className="signup-upload-license">
                                                    <label>
                                                        <span><img src="assets/images/credit-card 1.png" alt="Credit_Card" className="img-fluid" /></span>
                                                        <img src="assets/images/Upload.png" alt="Upload" className="img-fluid" />
                                                        Upload License Copy
                                                        <input type="file" className="d-none" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-dark login-btn w-100">Register Yourself</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="rd-login-img">
                                <img src="assets/images/login_img.png" alt="login-img" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default SignupTwo