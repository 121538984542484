import axios from 'axios';
import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { OM_API_URL } from '../BaseURL';

const Otp = () => {
    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(59);
    const [seconds, setSeconds] = useState(59);
    const location = useLocation();
    const email = location.state.email;
    const [err, setErr] = useState('');
    const nav = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const resendOTP = () => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('business_email', email);
        axios({
            method: 'post',
            url: `${OM_API_URL}/web/otp-resend`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
        }).then((response) => {
            if (response.data.status === true) {
                setMinutes(59);
                setSeconds(59);
                setErr('');
            }
        }).catch((err) => {
            setErr(err.response.data.message)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (otp) {

            if (location.state.type !== 'forgot') {
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('business_email', email);
                bodyFormData.append('otp', otp);
                axios({
                    method: 'post',
                    url: `${OM_API_URL}/web/otp-verification`,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data.status === true) {
                        //     setMinutes(1);
                        //     setSeconds(59);
                        nav('/subscription-plan');
                        localStorage.setItem('OM_TOKEN', response.data.data.token);
                    }
                }).catch((err) => {
                    setErr(err.response.data.message)
                })
            } else {
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('business_email', email);
                bodyFormData.append('otp', otp);
                axios({
                    method: 'post',
                    url: `${OM_API_URL}/web/forget-password-otp-verification`,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data.status === true) {
                        //     setMinutes(1);
                        //     setSeconds(59);
                        nav('/reset-password', { state: email });
                        // localStorage.setItem('OM_TOKEN', response.data.data.token);
                    }
                }).catch((err) => {
                    setErr(err.response.data.message)
                })
            }
        } else {
            setErr('Please enter otp')
        }
    }

    const handleHome = () => {
        window.open("https://omathomeapp.com")
    }

    return (
        <div>

            <main className="rd-site-content">
                <section className="rd-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="rd-login-content om-otp-verificarion-content">
                                <Link onClick={handleHome} className="rd-login-logo">
                                    <span className="rd-login-logo__img">
                                        <img src="assets/images/logo.svg" alt="logo" className="img-fluid" style={{ height: "100px", width: "auto" }} />
                                    </span>
                                </Link>
                                <div className="rd-login-form-content" data-scrollbar>
                                    <div className="rd-login-form-heading">
                                        <h1 className="rd-login-form-title">Verification Code</h1>
                                        <p className="rd-login-form-description">Enter the OTP you received via email address for further process</p>
                                    </div>
                                    <div className="rd-login-form rd-otp-form">
                                        <form className="row" autocomplete="off">
                                            <div className="col-md-12">
                                                <label for="inputUsername" className="form-label">OTP</label>
                                                <OtpInput
                                                    value={otp}
                                                    onChange={(val) => setOtp(val)}
                                                    numInputs={6}
                                                    inputStyle={{ width: '4rem', height: '4rem', marginRight: '15px' }}
                                                // separator={<span>       </span>}
                                                />
                                                <span className='error'>{err}</span>

                                                {/* <input type="" className="form-control" id="inputUsername" /> */}
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {seconds > 0 || minutes > 0 ? (
                                                        <p style={{ paddingTop: '30px' }}>
                                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) : (
                                                        <p style={{ paddingTop: '30px' }}>Didn't recieve code?</p>
                                                    )}
                                                    <button style={{ border: 'none', background: 'none', display: 'flex', justifyContent: 'flex-end', flex: 1, textDecoration: 'underline', cursor: 'pointer', color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "black", }}
                                                        disabled={seconds > 0 || minutes > 0}
                                                        onClick={resendOTP}
                                                        type="button"
                                                        className="rd-forgot-passlink">Resend OTP</button>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="button" onClick={handleSubmit} className="btn btn-dark login-btn w-100">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <p className="rd-site-reserved d-none d-lg-block">All rights reserved. © 2023 Om Outline</p>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            {window.innerWidth > 767 ?
                                <div className="rd-login-img">
                                    <img src="assets/images/login_img.jpg" alt="login-img" className="img-fluid w-100" />
                                </div>
                                : ""}
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default Otp