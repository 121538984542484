import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ScrollArea from 'react-scrollbar'
import { OM_API_URL } from '../BaseURL';
const Login = () => {
    const [details, setDetails] = useState({ email: "", password: "", pwdShow: true });
    const [error, setError] = useState({});
    const nav = useNavigate();

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const validation = () => {
        var isValid = true;
        let err = {};

        if (!details.email) {
            isValid = false;
            err['email_err'] = "Please enter your email address"
        }

        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter your password"
        }

        setError(err);
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validation()) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('business_email', details.email);
            bodyFormData.append('password', details.password)
            axios({
                method: 'post',
                url: `${OM_API_URL}/web/sign-in`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
            }).then((response) => {
                // console.log(response.data);
                if (response.data.verified === false) {
                    nav('/otp-verification', { state: { email: details.email, type: 'login' } })
                } else {
                    if (response.data.subscription === true) {
                        nav('/dashboard');
                    } else {
                        nav('/subscription-plan')
                    }
                    localStorage.setItem('OM_TOKEN', response.data.data.token);
                }
            }).catch((err) => {
                setError({ ...error, password_err: err.response.data.message })
            })
        }
    }

    const handleHome = () => {
        window.open("https://omathomeapp.com")
    }


    return (
        <div>
            <main className="rd-site-content">
                <section className="rd-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="rd-login-content">
                                <a onClick={handleHome} className="rd-login-logo" style={{ cursor: "pointer" }}>
                                    <span className="rd-login-logo__img">
                                        <img src="assets/images/logo.svg" alt="logo" className="img-fluid" style={{ height: "100px", width: "auto" }} />
                                    </span>
                                </a>
                                <ScrollArea speed={0.8}
                                    className="scroll-content"
                                    contentClassName="content"
                                    horizontal={false}
                                    smoothScrolling={true}
                                >
                                    <div className="rd-login-form-content" data-scrollbar>
                                        <div className="rd-login-form-heading">
                                            <h1 className="rd-login-form-title">Group Login</h1>
                                            <p className="rd-login-form-description">Sign into your account</p>
                                        </div>
                                        <div className="rd-login-form">
                                            <form className="row g-0" >
                                                <div className="col-md-12">
                                                    <label htmlFor="inputEmail" className="form-label">Email</label>
                                                    <input type="text" id="inputEmail" className={error.email_err ? "form-control" : "form-control"} name="email" onChange={handleChange} />
                                                    <span className='error'>{error.email_err}</span>
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="inputPassword" className="form-label">Password</label>
                                                    <div class="password-field">
                                                        <input type={details.pwdShow ? "password" : "text"} id="inputPassword" className={error.password_err ? "form-control" : "form-control"} name="password" onChange={handleChange} />
                                                        {details.pwdShow ? <i className='fa fa-eye' onClick={() => setDetails({ ...details, pwdShow: false })} /> : <i className='fa fa-eye-slash' onClick={() => setDetails({ ...details, pwdShow: true })} />}
                                                    </div>
                                                    <span className='error'>{error.password_err}</span>

                                                </div>
                                                <div className="col-12">
                                                    <button type="button" onClick={handleSubmit} className="btn btn-dark login-btn w-100">Sign in</button>
                                                    <div className="text-end">
                                                        <Link to="/forgot-password" className="rd-forgot-passlink">Forgot
                                                            Password?</Link>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="text-center">
                                                        <p className="login-notes">Don’t Have an Account ? <Link to="/signup">Create
                                                            One</Link></p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </ScrollArea>
                                <p className="rd-site-reserved d-none d-lg-block">All rights reserved. © 2023 Om Outline</p>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            {window.innerWidth > 767 ?
                                <div className="rd-login-img">
                                    <img src="assets/images/login_img.jpg" alt="login-img" className="img-fluid w-100" />
                                </div>
                                : ""}
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default Login