import React from 'react'

const TermsCondition = () => {
    return (
        <div className='container' style={{ fontFamily: "Source sans pro" }}>
            <div className='mt-5'>
                <h5>Term of Service 03/01/2023</h5>
                <h5>OM Worldwide Inc. DBA OM At Home</h5>
                <div style={{ color: "#969AA3" }}>
                    <p>
                        By using OM At Home’s website, app, and engaging in  any on or off-platform events collectively “the services”
                        you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services.
                    </p>
                </div>
                <div>
                    <h4 style={{ fontWeight: "600" }}>Use of the services:</h4>
                    <p style={{ color: "#969AA3" }}>
                        Your use of our Services is subject to our Privacy Policy on how we use and collect information from our users.
                        We will let you know a change to our terms by posting the modified Terms on the Site.  To make a subscription purchase on
                        OM At Home’s app or website, you must be 18 years or older and capable of forming a binding contract. You’ll notify OM At
                        Home immediately of any unauthorized use of your Account. You agree that you won’t disclose your account password to anyone.
                        You’re responsible for all activities that occur under your account, whether or not you know about them.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        You agree to keep account information accurate, complete and up-to-date. If you don’t, we might have to suspend or terminate
                        your Account. You understand and agree that the information you learn from OM At Home is not intended, designed, or implied to
                        diagnose, prevent, or treat any condition or disease, to ascertain the state of your health, or to be a substitute for
                        professional medical care.  Not all classes are suitable for everyone. You are solely responsible for your use of OM At Home.
                        Do not use OM At Home while driving or performing tasks that require attention and concentration.
                    </p>
                    <h4 style={{ fontWeight: "600" }}>Purchases:</h4>
                    <p style={{ color: "#969AA3" }} >
                        All amounts are charged for monthly subscriptions at the beginning of the subscription. Each such subscription renews automatically
                        for an additional period equal in length to the expiring subscription term until you cancel it, at the time of each renewal until you
                        cancel, using the Payment Information you have provided. You must cancel your Subscription before it renews to avoid the billing of
                        the fees for the next Subscription period. You can cancel the renewal of your subscription at any time by contacting us by email at <a href='mailto:hello@OMatHome.co'>hello@OMatHome.co </a>.
                        If you purchase your Subscription via an App Provider, you can cancel the renewal of your subscription at any
                        time with the App Provider. You will not receive a refund for the fees you already paid for your current subscription period and you
                        will continue to receive the Services ordered until the end of your current Subscription period.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        OM At Home reserves the right to change its pricing terms for Subscriptions at any time. Changes to the pricing terms will not apply
                        retroactively and will only apply for Subscription renewals after such changed pricing terms have been communicated to you. If you do
                        not agree with the changes to Calm’s pricing terms then you may choose not to renew your Subscription.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        You agree that your purchases are not contingent on any oral or written public comments made by OM At Home regarding future functionality or features.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        If you have any concerns or objections regarding charges, you agree to raise them with us first and you agree not to cancel or reject any
                        credit card or third party payment processing charges unless you have made a reasonable attempt at resolving the matter directly with
                        OM At Home. You agree that disputes between you and OM At Home will be resolved by individual arbitration. You are waiving your right
                        to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        When you purchase a Subscription or a Product (each, a “Transaction”), we may ask you to supply additional information relevant to your Transaction,
                        such as your credit card number, the expiration date of your credit card and your address(es) for billing and delivery
                        (such information, “Payment Information”). You represent and warrant that you have the legal right to use all payment method(s)
                        represented by any such Payment Information. The amounts due and payable by you for a Transaction through the Services will be presented
                        to you before you place your order.  If you choose to initiate a Transaction via the Services, you authorize us to provide your Payment
                        Information to third party service providers so we can complete your Transaction and agree (a) to pay the applicable fees and any taxes;
                        (b) that OM At Home may charge your credit card or third party payment processing account, including, but not limited to, your account
                        with the app store or distribution platform (like the Apple App Store, Google Play or the Amazon Appstore) where the App is made available
                        (each, an “App Provider”), for verification, pre-authorization and payment purposes; and (c) to bear any additional charges that your App
                        Provider, bank or other financial service provider may levy on you as well as any taxes or fees that may apply to your order. All payments
                        made are non-refundable and non-transferable.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        You may need to provide additional information to verify your identity before completing your Transaction.
                        OM At Home will either not charge you or refund the charges for orders that we do not process or cancel.
                        OM At Home reserves the right to not process or to cancel your order in certain circumstances, for example,
                        if your credit card is declined, if we suspect the request or order is fraudulent, or in other circumstances
                        OM At Home deems appropriate.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice to you.
                        You may cancel your Account at any time by sending an email to us at <a href='mailto:hello@OMatHome.co'>hello@OMatHome.co </a>.
                    </p>
                    <h4 style={{ fontWeight: "600" }}>Content terms:</h4>
                    <p style={{ color: "#969AA3" }}>
                        For purposes of these Terms, (i) “Content” means text, graphics, images, music, software, audio, video, works of authorship of
                        any kind, and information or other materials that are posted, generated, provided or otherwise made available through the
                        Services; and (ii) “User Content” means any Content that users (including you) provide to be made available through the
                        Services. Content includes without limitation User Content.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        OM At Home and its licensors exclusively own all right, title and interest in the Services and Content, including all associated intellectual property rights.
                        Subject to your strict compliance with these Terms and any Additional Terms, we grant to you a non-exclusive, non-transferable, revocable,
                        non-assignable, personal, limited license to download, display, view, use and play the Content on a personal computer, browser, laptop, tablet,
                        mobile phone, or other internet-enabled device (each, a “Device”), in each case for your personal, non-commercial use only. All content,
                        including the “look and feel” of the Service can not be used otherwise.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        By creating an account, you hereby grant to OM At Home a non-exclusive, transferable, sublicenseable, worldwide, royalty-free license to use, copy, modify,
                        create derivative works based upon, distribute, publicly display, publicly perform and distribute your User Content in connection with operating
                        and providing the Services and Content to you and to other users.
                    </p>
                    <p style={{ color: "#969AA3" }}>
                        You agree not to do any of the following:
                    </p>
                    <ul style={{ color: "#969AA3" }}>
                        <li>
                            Post, upload, publish, submit or transmit any Content that: (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or substances. Use, display, mirror or frame the Services, or any individual element within the Services, OM At Home’s name, any OM At Home trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without OM At Home’s express written consent. This includes, but is not limited to profile pictures and message boards.
                        </li>
                        <li>
                            Access, tamper with, or use non-public areas of the Services, OM At Home’s computer systems, or the technical delivery systems of OM At Home’s providers;
                        </li>
                        <li>
                            Attempt to probe, scan, or test the vulnerability of any OM At Home system or network or breach any security or authentication measures;
                        </li>
                        <li>
                            Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by OM At Home or any of OM At Home’s providers or any other third party (including another user) to protect the Services;
                        </li>
                        <li>
                            Attempt to access or search the Services or download Collective Content from the Services through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Calm or other generally available third party web browsers;
                        </li>
                        <li>
                            Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation;
                        </li>
                        <li>
                            Use any meta tags or other hidden text or metadata utilizing a OM At Home trademark, logo URL or product name without OM At Home’s express written consent;
                        </li>
                        <li>
                            Use the Services for any commercial purpose or the benefit of any third party or in any manner not permitted by these Terms;
                        </li>
                        <li>
                            Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services;
                        </li>
                        <li>
                            Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
                        </li>
                        <li>
                            Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services to send altered, deceptive or false source-identifying information;
                        </li>
                        <li>
                            Impersonate or misrepresent your affiliation with any person or entity;
                        </li>
                        <li>
                            Collect or store any personally identifiable information from the Services from other users of the Services without their express permission;
                        </li>
                        <li>
                            Violate any applicable law or regulation; or
                        </li>
                        <li>
                            Violate any applicable law or regulation; or
                        </li>
                        <li>
                            Encourage or enable any other individual to do any of the foregoing.
                        </li>
                    </ul>
                    <div style={{ color: "#969AA3" }}>
                        <p>
                            Although we’re not obligated to monitor access to or use of the Services or Content or to review or edit any Content, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms, and to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to remove or disable access to any Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider any Content to be objectionable or in violation of these Terms
                        </p>
                        <p>
                            The Services and App may contain links to third-party websites or resources. We provide these links only as a convenience and are not responsible for the content, products or services on or available from those websites or resources or links displayed on such websites.
                        </p>
                        <p>
                            The Services, Products and Content are provided “as is,” without warranty of any kind. We make no warranty about health or any other benefits to our services.
                        </p>
                        <p>
                            We make no warranty that the Services or Products will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any Content.
                        </p>
                        <p>
                            You will indemnify and hold harmless OM At Home and its officers, directors, employee and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with (i) your access to or use of the Services or Content or (ii) your violation of these Terms.
                        </p>
                        <p>
                            WE ASSUME NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR FAILURE OF THE SERVICES OR TO STORE OR DISPLAY ANY CONTENT, USER COMMUNICATIONS OR PERSONALIZATION SETTINGS. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM USE OF THE SERVICES.
                        </p>
                        <p>
                            Neither OM At Home nor any other party involved in creating, producing, or delivering the Services, Products or Content will be liable for any incidental, special, exemplary or consequential damages, including, but not limited to, lost profits, loss of data or goodwill, service interruption, computer damage or system failure or the cost of substitute Services or Products arising out of or in connection with these terms or from the use of or inability to use the Services, Products or Content, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and whether or not OM At Home has been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed of its essential purpose.
                        </p>
                    </div>
                    <h4 style={{ fontWeight: "600" }}>Dispute resolution:</h4>
                    <div style={{ color: "#969AA3" }}>
                        <p>
                            In no event will OM At Home’s total liability arising out of or in connection with these terms or from the use of or inability to use the Services, Products or Content exceed the amounts you have paid to OM At Home for use of the Services, Products or Content or fifty dollars ($50), if you have not had any payment obligations to OM At Home, as applicable. The exclusion and limitations of damages set forth above are fundamental elements of the basis of the bargain between OM At Home and you.
                        </p>
                        <p>
                            You and OM At Home agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services, Products or Content (collectively, “Disputes”) will be settled by binding arbitration, except that each party retains the right: (i) to bring an individual action in small claims court and (ii) to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights (the action described in the foregoing clause (ii), an “IP Protection Action”).
                        </p>
                        <p>
                            You will also have the right to litigate any other Dispute if you provide Calm with written notice of your desire to do so by email at <a href='mailto:hello@OMatHome.co'>hello@OMatHome.co </a>  within thirty (30) days following the date you first agree to these Terms (such notice, an “Arbitration Opt-out Notice”). If you don’t provide OM At Home with an Arbitration Opt-out Notice within the thirty (30) day period, you will be deemed to have knowingly and intentionally waived your right to litigate any Dispute except as expressly set forth in clauses above.
                        </p>
                        <p>
                            You waive any right to pursue an action on a class-wide basis against us and may only resolve disputes with us on an individual basis, and may not bring a claim against us as a plaintiff or a class member in a class, consolidated, or representative action
                        </p>
                    </div>
                    <h4 style={{ fontWeight: "600" }}>Apple App store:</h4>
                    <div style={{ color: "#969AA3" }}>
                        <p>
                            If you accessed or downloaded the App from the Apple Store, then you agree to use the App only: (i) on an Apple-branded product or device that runs iOS (Apple’s proprietary operating system software); and (ii) as permitted by the “Usage Rules” set forth in the Apple Store Terms of Service
                        </p>
                        <p>
                            If you accessed or downloaded the App from an App Provider, then you acknowledge and agree that:
                        </p>
                        <ul>
                            <li>
                                These Terms are concluded between you and OM At Home, and not with App Provider, and that, as between OM At Home and the App Provider, OM At Home, is solely responsible for the App.
                            </li>
                            <li>
                                App Provider has no obligation to furnish any maintenance and support services with respect to the App.
                            </li>
                            <li>
                                App Provider is not responsible for addressing any claims you have or any claims of any third party relating to the App or your possession and use of the App.
                            </li>
                            <li>
                                In the event of any third-party claim that the App or your possession and use of the App infringes that third party’s intellectual property rights, OM At Home will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms
                            </li>
                            <li>
                                App Provider and its subsidiaries are third-party beneficiaries of these Terms as related to your license of the App, and that, upon your acceptance of the terms and conditions of these Terms, App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms.
                            </li>
                            <li>
                                You must also comply with all applicable third-party terms of service when using the App.
                            </li>
                            <li>
                                You agree to comply with all U.S. and foreign export laws and regulations to ensure that neither the App nor any technical data related thereto nor any direct product thereof is exported or re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such laws and regulations. By using the App you represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
                            </li>
                        </ul>
                    </div>
                    <h4 style={{ fontWeight: "600" }}>General Terms:</h4>
                    <div style={{ color: "#969AA3" }}>
                        <p>
                            These Terms constitute the entire and exclusive understanding and agreement between OM At Home and you regarding the Services, Products and Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between OM At Home and you regarding the Services, Products and Content. If any provision of these Terms is held invalid or unenforceable (either by an arbitrator appointed pursuant to the terms of the “Arbitration” section above or by court of competent jurisdiction, but only if you timely opt out of arbitration by sending us an Arbitration Opt-out Notice in accordance with the terms set forth above), that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect.
                        </p>
                        <p>
                            You may not assign or transfer these Terms, by operation of law or otherwise, without OM At Home’s prior written consent. OM At Home may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind to the benefit of the parties, their successors and permitted assigns.
                        </p>
                        <p>
                            For notices made by e-mail or by posting to the Services, the date sent or posted will be deemed the date on which such notice is transmitted.
                        </p>
                        <p>
                            OM At Home’s failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision.
                        </p>
                        <p>
                            If you give us feedback, you grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sublicenseable and transferable license under any and all intellectual property rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit that feedback
                        </p>
                        <p>
                            If you have any questions about these Terms or the Services or Products, please contact OM At Home at <a href='mailto:hello@OMatHome.co'>hello@OMatHome.co </a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsCondition