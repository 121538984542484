import axios from 'axios';
import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ScrollArea from 'react-scrollbar'
import { OM_API_URL } from '../BaseURL';
import { toast, Toaster } from 'react-hot-toast'


const Signup = () => {
    const [isForm1Hidden, setIsForm1Hidden] = useState(false);
    const [isForm2Hidden, setIsForm2Hidden] = useState(true);
    const formRef = useRef();

    const [details, setDetails] = useState({
        // first_name: "",
        // last_name: "",
        // email: "",
        mobile: "",
        // owner: true,
        city: "",
        password: "",
        confirm_password: "",
        pwdShow: true,
        cpwdShow: true,
        business_name: "",
        business_email: "",
        // users: "",
        // business_type: "School",
        // license: ""
    });
    const [error, setError] = useState({});
    const nav = useNavigate();

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        // if (!details.first_name) {
        //     isValid = false;
        //     err['first_name_err'] = "Please enter first name"
        // }

        // if (!details.last_name) {
        //     isValid = false;
        //     err['last_name_err'] = "Please enter last name"
        // }

        // if (!details.email) {
        //     isValid = false;
        //     err['email_err'] = "Please enter email"
        // } else if (typeof details.email !== "undefined") {
        //     let lastAtPos = details.email.lastIndexOf('@');
        //     let lastDotPos = details.email.lastIndexOf('.');

        //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && details.email.indexOf('@@') === -1 && lastDotPos > 2 && (details.email.length - lastDotPos) > 2)) {
        //         isValid = false;
        //         err["email_err"] = "Email is not valid";
        //     }
        // }

        if (!details.mobile) {
            isValid = false;
            err['mobile_err'] = "Please enter mobile number"
        }

        if (!details.city) {
            isValid = false;
            err['city_err'] = "Please enter city"
        }

        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        }

        if (!details.confirm_password) {
            isValid = false;
            err['confirm_password_err'] = "Please enter confirm password"
        }


        if (details.password && details.confirm_password) {
            if (details.password.toLowerCase() !== details.confirm_password.toLowerCase()) {
                isValid = false;
                err['confirm_password_err'] = "Password doesn't match"
            }
        }

        setError(err);
        return isValid
    }

    const validation2 = () => {
        let isValid = true;
        let err = {};

        if (!details.business_name) {
            isValid = false;
            err['business_name_err'] = "Please enter business name"
        }

        if (!details.business_email) {
            isValid = false;
            err['business_email_err'] = "Please enter business email"
        } else if (typeof details.business_email !== "undefined") {
            let lastAtPos = details.business_email.lastIndexOf('@');
            let lastDotPos = details.business_email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && details.business_email.indexOf('@@') === -1 && lastDotPos > 2 && (details.business_email.length - lastDotPos) > 2)) {
                isValid = false;
                err["business_email_err"] = "Business email is not valid";
            }
        }
        // if (!details.mobile) {
        //     isValid = false;
        //     err['mobile_err'] = "Please enter mobile number"
        // }

        if (!details?.mobile) {
            isValid = false;
            err['mobile_err'] = "Please enter mobile number";
        } else {
            const mobilePattern = /^[0-9]{10}$/; // Change this pattern based on your requirements
            if (!mobilePattern.test(details?.mobile)) {
                isValid = false;
                err['mobile_err'] = "Please enter a valid 10-digit mobile number";
            }
        }


        if (!details.city) {
            isValid = false;
            err['city_err'] = "Please enter city"
        }

        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        }

        if (!details.confirm_password) {
            isValid = false;
            err['confirm_password_err'] = "Please enter confirm password"
        }


        if (details.password && details.confirm_password) {
            if (details.password.toLowerCase() !== details.confirm_password.toLowerCase()) {
                isValid = false;
                err['confirm_password_err'] = "Password doesn't match"
            }
        }

        // if (!details.users) {
        //     isValid = false;
        //     err['users_err'] = "Please enter users"
        // }

        // if (!details.license) {
        //     isValid = false;
        //     err['license_err'] = "Please upload license image"
        // }

        setError(err);
        return isValid
    }

    const swapForm = (e) => {
        e.preventDefault();
        if (validation()) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', details.email);
            axios({
                method: 'post',
                url: `${OM_API_URL}/web/check-email`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.data.status === true) {
                    setIsForm1Hidden(!isForm1Hidden);
                    setIsForm2Hidden(!isForm2Hidden);
                    setTimeout(() => {
                        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }, 1000)
                }

            }).catch((err) => {
                console.log(err);
                setError({ ...error, email_err: "Email is already used" })
            })
        }
    }

    const handleImage = (e) => {
        let img = e.target.files[0];
        var bodyFormData = new FormData();
        bodyFormData.append('image', img);
        axios({
            method: "post",
            url: `${OM_API_URL}/upload-image`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            if (response.data.status === true) {
                setDetails({ ...details, license: response.data.data.url });
            }
        })
    }

    const handleSignUp = (e) => {
        e.preventDefault();
        if (validation2()) {
            var bodyFormData = new URLSearchParams();
            // bodyFormData.append('first_name', details.first_name);
            // bodyFormData.append('last_name', details.last_name);
            // bodyFormData.append('email', details.email);
            bodyFormData.append('mobileno', details.mobile);
            // bodyFormData.append('is_owner', details.owner);
            bodyFormData.append('city', details.city);
            bodyFormData.append('password', details.password);
            bodyFormData.append('business_name', details.business_name);
            bodyFormData.append('business_email', details.business_email);
            // bodyFormData.append('business_type', details.business_type);
            // bodyFormData.append('total_user', details.users);
            // bodyFormData.append('license_image', details.license);
            axios({
                method: 'post',
                data: bodyFormData,
                url: `${OM_API_URL}/web/sign-up`,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.data.status === true) {
                    nav('/otp-verification', { state: { email: details.business_email, type: 'signup' } })
                }
            }).catch((err) => {
                console.log(err);
                toast.error(err.response.data.message)
            })
        }
    }

    const handleHome = () => {
        window.open("https://omathomeapp.com")
    }

    return (
        <div>
            <Toaster />
            <main className="rd-site-content">
                <section className="rd-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="rd-login-content om-signup-content" >

                                <a onClick={handleHome} className="rd-login-logo" style={{ cursor: "pointer" }}>
                                    <span className="rd-login-logo__img">
                                        <img src="assets/images/logo.svg" alt="logo" className="img-fluid" style={{ height: "100px", width: "auto" }} />
                                    </span>
                                </a>
                                <ScrollArea speed={1.2}
                                    className="scroll-content"
                                    contentClassName="content"
                                    horizontal={false}
                                    smoothScrolling={true}
                                >
                                    <div className="rd-login-form-content" data-scrollbar style={{ overflow: "auto" }} id='signup-page-1' >
                                        <div className="rd-login-form-heading">
                                            <h1 className="rd-login-form-title">Group Sign-Up</h1>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>
                                                <p className="rd-login-form-description">Group Details</p>
                                                {
                                                    isForm1Hidden ?
                                                        <span onClick={swapForm} style={{ float: "right", textDecoration: "underline", marginRight: "10px" }}>
                                                            {"Back"}
                                                        </span>
                                                        : ""
                                                }
                                            </div>

                                        </div>
                                        <div className="rd-login-form">

                                            {/* <div className="row" autocomplete="off" hidden={isForm1Hidden}> */}
                                            <div className='row'>
                                                {/* <div className="col-md-12 col-lg-6">
                                                    <label for="inputUsername" className="form-label">First name</label>
                                                    <input type="text" className={error.first_name_err ? "form-control error" : "form-control"} id="inputUsername" name="first_name" onChange={handleChange} value={details.first_name} />
                                                    <span className='error'>{error.first_name_err}</span>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <label for="inputPassword4" className="form-label">Last name</label>
                                                    <input type="text" className={error.last_name_err ? "form-control error" : "form-control"} id="inputPassword4" name="last_name" onChange={handleChange} value={details.last_name} />
                                                    <span className='error'>{error.last_name_err}</span>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="inputPassword4" className="form-label">Email Address </label>
                                                    <input type="email" className={error.email_err ? "form-control error" : "form-control"} id="inputPassword3" name="email" onChange={handleChange} value={details.email} />
                                                    <span className='error'>{error.email_err}</span>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <label for="inputUsername" className="form-label">Group Name</label>
                                                    <input type="text" className={error.business_name_err ? "form-control " : "form-control"} id="inputUsername" name="business_name" onChange={handleChange} value={details.business_name} />
                                                    <span className='error'>{error.business_name_err}</span>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="inputPassword4" className="form-label">Group Email</label>
                                                    <input type="text" className={error.business_email_err ? "form-control " : "form-control"} id="inputPassword7" name="business_email" onChange={handleChange} value={details.business_email} />
                                                    <span className='error'>{error.business_email_err}</span>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="inputPassword4" className="form-label">Mobile Number</label>
                                                    <input type="number" className={error.mobile_err ? "form-control " : "form-control"} id="inputPassword2" name="mobile" onChange={handleChange} value={details.mobile} />
                                                    <span className='error'>{error.mobile_err}</span>
                                                </div>
                                                {/* <div className="col col-12">
                                                    <label for="inputPassword4" className="form-label">Are You Owner ?</label>
                                                    <div className="om-signup-radio-btn">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="signupRadio1" checked={details.owner === true} onChange={(e) => { setDetails({ ...details, owner: true }) }} />
                                                            <label className="form-check-label" for="signupRadio1">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="signupRadio2" checked={details.owner === false} onChange={(e) => { setDetails({ ...details, owner: false }) }} />
                                                            <label className="form-check-label" for="signupRadio2">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <label for="inputPassword4" className="form-label">City</label>
                                                    <input type="text" className={error.city_err ? "form-control " : "form-control"} id="inputPassword" name="city" onChange={handleChange} value={details.city} />
                                                    <span className='error'>{error.city_err}</span>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <label for="inputPassword4" className="form-label">Password</label>
                                                    <div className='password-field'>
                                                        <input type={details.pwdShow ? "password" : "text"} className={error.password_err ? "form-control " : "form-control"} id="inputPassword5" name="password" onChange={handleChange} value={details.password} />
                                                        {details.pwdShow ? <i className='fa fa-eye' onClick={() => setDetails({ ...details, pwdShow: false })} /> : <i className='fa fa-eye-slash' onClick={() => setDetails({ ...details, pwdShow: true })} />}
                                                    </div>
                                                    <span className='error'>{error.password_err}</span>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <label for="inputPassword4" className="form-label">Confirm Password</label>
                                                    <div className='password-field'>
                                                        <input type={details.cpwdShow ? "password" : "text"} className={error.confirm_password_err ? "form-control " : "form-control"} id="inputPassword6" name="confirm_password" onChange={handleChange} value={details.confirm_password} />
                                                        {details.cpwdShow ? <i className='fa fa-eye' onClick={() => setDetails({ ...details, cpwdShow: false })} /> : <i className='fa fa-eye-slash' onClick={() => setDetails({ ...details, cpwdShow: true })} />}
                                                    </div>
                                                    <span className='error'>{error.confirm_password_err}</span>
                                                </div>
                                                {/* <div className="col-12">
                                                    <button className="btn btn-dark login-btn w-100" onClick={swapForm}>Next</button>
                                                </div> */}
                                            </div>
                                            {/* ----------------------------------------------------------------------------- */}
                                            {/* ----------------------------page 2 sign up  --------------------------------- */}
                                            {/* ----------------------------------------------------------------------------- */}
                                            {/* <div className="row" autocomplete="off" hidden={isForm2Hidden} ref={formRef}> */}
                                            <div className='row' >
                                                {/* <div className="col-md-12">
                                                    <label for="inputUsername" className="form-label">Business Name</label>
                                                    <input type="text" className={error.business_name_err ? "form-control error" : "form-control"} id="inputUsername" name="business_name" onChange={handleChange} value={details.business_name} />
                                                    <span className='error'>{error.business_name_err}</span>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="inputPassword4" className="form-label">Business Email</label>
                                                    <input type="text" className={error.business_email_err ? "form-control error" : "form-control"} id="inputPassword7" name="business_email" onChange={handleChange} value={details.business_email} />
                                                    <span className='error'>{error.business_email_err}</span>
                                                </div> */}
                                                {/* <div className="col-md-12 col-lg-6">
                                                    <label for="inputPassword4" className="form-label">Total Users</label>
                                                    <input type="number" className={error.users_err ? "form-control error" : "form-control"} id="inputPassword8" name="users" onChange={handleChange} value={details.users} />
                                                    <span className='error'>{error.users_err}</span>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <label for="inputPassword4" className="form-label">Business Type</label>
                                                    <select className={error.business_type_err ? "form-select form-control error" : "form-select form-control"} value={details.business_type} name="business_type" onChange={handleChange}>
                                                        <option value="School">School</option>
                                                        <option value="NGO">NGO</option>
                                                        <option value="Startups">Startups</option>
                                                    </select>
                                                    <span className='error'>{error.business_type_err}</span>
                                                </div> */}
                                                {/* <div className="col-12">
                                                    <div className="signup-upload-license">
                                                        <label>
                                                            {!details.license ?
                                                                <><span><img src="assets/images/credit-card 1.png" alt="Credit_Card" className="img-fluid" /></span>
                                                                    <img src="assets/images/Upload.png" alt="Upload" className="img-fluid" />
                                                                    Upload License Copy </> :
                                                                <img src={details.license} className="img-fluid" />}
                                                            <input type="file" className="d-none" onChange={handleImage} />
                                                        </label>
                                                        <span className='error'>{error.license_err}</span>
                                                    </div>
                                                </div> */}
                                                <div className="col-12">
                                                    <button type="button" className="btn btn-dark login-btn w-100" onClick={handleSignUp}>Register Group</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="text-center">
                                            <p className="login-notes">Already have an Account ? <Link to="/login">Login</Link></p>
                                        </div>
                                    </div>
                                </ScrollArea>
                            </div>

                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            {window.innerWidth > 767 ?
                                <div className="rd-login-img">
                                    <img src="assets/images/login_img.jpg" alt="login-img" className="img-fluid w-100" />
                                </div>
                                : ""}
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default Signup