import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { OM_API_URL } from '../BaseURL';
// import parse from 'html-react-parser';

const SubscriptionPlan = () => {
    useEffect(() => {
        getSubscriptionPlan();
    }, []);
    const [plans, setPlans] = useState([]);

    const getSubscriptionPlan = () => {
        axios({
            method: 'get',
            url: `${OM_API_URL}/web/get-subscription-plans`,
            headers: { 'Content-Type': 'application/json' }
        }).then(async (response) => {
            if (response.data.status === true) {
                let data = await Promise.all(response.data.data.map(elem => {
                    return { ...elem, description: elem.description.split('\n') }
                }))
                setPlans(data)
            }
        })
    }
    return (
        <section className="subscription-section" id='subscription'>
            <div className="container">
                <div className="subscription-content">
                    <h2 className="section-title" style={{ textAlign: 'center' }}>Subscription Plans</h2>


                    <div className="row">
                        {plans && plans.map((elem) =>
                                <div className="col col-12 col-md-6 col-lg-4">
                                    <div className="subscription-plans">
                                        <div className="subscription-plans-details">
                                            <h4 className="subscription-title">{elem.plan_name}</h4>
                                            <h3 className="subscription-price">${elem.amount}<br /><span></span>
                                            </h3>
                                          
                                            {elem.description.map(e =>
                                                <ul className="subscription-plans-feature">
                                                    <li>{e.split('. ')[1]} </li>
                                                </ul>
                                            )}
                                        </div>
                                        <div className="subscription-plans-footer">
                                            <Link to='/payment' state={elem} className="btn btn-subscription">Subscribe</Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        {/* {plans && plans.length > 0 && (
                            <div className="col col-12 col-md-6 col-lg-4">
                                <div className="subscription-plans">
                                    <div className="subscription-plans-details">
                                        <h4 className="subscription-title">{plans[0].plan_name}</h4>
                                        <h3 className="subscription-price">${plans[0].amount}<br /><span></span>
                                        </h3>
                                        {plans[0].description.map(e => (
                                            <ul className="subscription-plans-feature" key={e}>
                                                <li>{e.split('. ')[1]}</li>
                                            </ul>
                                        ))}
                                    </div>
                                    <div className="subscription-plans-footer">
                                        <Link to='/payment' state={plans[0]} className="btn btn-subscription">Subscribe</Link>
                                    </div>
                                </div>
                            </div>
                        )} */}

                    </div>

                </div>
            </div>
        </section >
    )
}

export default SubscriptionPlan