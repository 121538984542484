import React from 'react'

const About = () => {
    return (
        <div className='container' style={{ fontFamily: "Source sans pro" }}>
            <div className='mt-3'>
                <h4 style={{ fontWeight: "600" }}>Introduction</h4>
                <div style={{ color: "#969AA3" }}>
                    <p>   Welcome  to OM At Home, the platform for live streamed meditation and wellness classes.
                        Our mission is to provide refuge and techniques to help you manage symptoms related to trauma and stress.</p>

                    <p>     The OM At Home team is made up of social justice leaders and mental health experts.
                        Our guides are best-in-class in the fields of mental health, wellness, trauma, and healing. For example,
                        Oneika Mays is a mindfulness coach who has worked with incarcerated individuals at Rikers Island Correctional
                        Facility. Dr. Tequillah Hill is a licensed therapist who specializes in somatics, which is a technique for
                        calming the nervous system. </p>
                    <p>
                        At OM At Home, we pride ourselves on being inclusive, judgment-free, motivating, diverse, and authentic.
                        We are not about over-the-top, preachy, fad, or gimmicky wellness practices. Here, you belong to something. We see you.
                    </p>
                </div>
            </div>

            <div className='my-4'>
                <h4 style={{ fontWeight: "600" }}>Our Product</h4>
                <div style={{ color: "#969AA3" }}>
                    <p>   OM At Home offers live-streamed meditation and wellness classes designed to help you manage symptoms related to trauma and stress.
                        Our monthly subscription model is available to single users as well as organizations.
                        Organizations get access to an easy-to-navigate desktop dashboard that allows login for up to 100 people.</p>

                    <p>    We offer a diverse range of daily classes, from a 5-minute anxiety release to a 45-minute healing session led by world-class guides.
                        Users show up to live classes as a community and support one another through the scrolling comments board.
                        We encourage users to further support our guides in their mission to bring peace by donating money to them directly during classes.
                    </p>

                    <p>
                        Our platform is diverse, inclusive, motivational, and truthful.
                         Browse our list of class descriptions to begin your healing journey and find the classes that speak to you.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default About