import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import Index from './components/Index'
import Dashboard from './components/Dashboard';
import InviteUser from './components/InviteUser';
import OrderSummaryCheckout from './components/OrderSummaryCheckout';
import Payment from './components/Payment';
import Support from './components/Support';
import CancelSubscription from './components/CancelSubscription';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import Signup from './components/Signup';
import Otp from './components/Otp';
import SignupTwo from './components/SignupTwo';
import ForgetPassword from './components/ForgetPassword';
import SubscriptionPlan from './components/SubscriptionPlan';
import EditUser from './components/EditUser';
import About from './components/About';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsCondition from './components/TermsCondition';

function App() {
  // window.onpopstate = function (event) {
  //   window.location.reload()
  // }
  const nav = useNavigate();
  nav(0);

  return (

    <div className="App">
      <Routes>
        {/* <Route exact path='/' element={<Index />} /> */}
        <Route exact path='/dashboard' element={<Dashboard />} />

        <Route exact path='/about-us' element={<About />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/terms-and-conditions' element={<TermsCondition />} />

        <Route exact path='/invite-user' element={<InviteUser />} />
        <Route exact path='/edit-user' element={<EditUser />} />
        <Route exact path='/order-summary&checkout' element={<OrderSummaryCheckout />} />
        <Route exact path='/payment' element={<Payment />} />
        <Route exact path='/support' element={<Support />} />
        <Route exact path='/cancel-subscription' element={<CancelSubscription />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/forgot-password' element={<ForgetPassword />} />
        <Route exact path='/reset-password' element={<ResetPassword />} />
        <Route exact path='/signup' element={<Signup />} />
        <Route exact path='/otp-verification' element={<Otp />} />
        <Route exact path='/signup-two' element={<SignupTwo />} />
        <Route exact path='/subscription-plan' element={<SubscriptionPlan />} />

        
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}
export default App;
