import React, { useEffect, useState } from 'react'
import DashboardHeader from './DashboardHeader'
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
import { OM_API_URL } from '../BaseURL'
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'
import './react-confirm-alert.css'; // Import css
import { toast, Toaster } from 'react-hot-toast'
import Spinner from './Spinner'

const Dashboard = () => {
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);
    const [displayStudents, setDisplayStudents] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const studentPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        if (localStorage.getItem('OM_TOKEN') !== null) {
            console.log(localStorage.getItem('OM_TOKEN'));
            axios({
                method: 'post',
                url: `${OM_API_URL}/web/check-subscription`,
                headers: { 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN')}` }
            }).then((response) => {
                if (response.data.status === true) {
                    getStudentList();
                } else {
                    setSpinner(false)
                    navigate('/subscription-plan')
                }
            })
        }
    }, [])

    const getStudentList = () => {
        axios({
            method: 'post',
            url: `${OM_API_URL}/web/get-staff-student-data`,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN')}` }
        }).then((response) => {
            if (response.data.status === true) {
                setStudents(response.data.data)
            }
            setSpinner(false)
        })
    }

    useEffect(() => {
        setDisplayStudents(students);
    }, [students])

    const indexOfLastStudent = currentPage * studentPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentPerPage;
    const currentStudents = displayStudents.slice(indexOfFirstStudent, indexOfLastStudent);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(displayStudents.length / studentPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (num) => {
        setCurrentPage(num);
    }

    const handleDelete = (e, id) => {
        e.preventDefault();
        const options = {
            title: 'Delete User',
            message: 'Are you sure want to delete the user?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { deleteUser(id) }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options);
    }

    const deleteUser = (id) => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('user_id', id);
        axios({
            method: 'post',
            url: `${OM_API_URL}/web/delete-staff-student`,
            data: bodyFormData,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN')}` }
        }).then((response) => {
            if (response.data.status === true) {
                toast.success('User Deleted', { style: { background: '#333', color: '#fff' } });
                getStudentList()
            }
        })
    }

    const reInviteUser = (id) => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('user_id', id);
        axios({
            method: 'post',
            url: `${OM_API_URL}/web/re-invite-staff-student`,
            data: bodyFormData,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN')}` }
        }).then((response) => {
            if (response.data.status === true) {
                toast.success('User Re-Invited', { style: { background: '#333', color: '#fff' } });
                getStudentList();
            }
        })
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            setDisplayStudents(students.filter(
                (elem) =>
                    elem.staff_student_name.toLowerCase().includes(search.toLowerCase()) ||
                    elem.staff_student_email.toLowerCase().includes(search.toLowerCase())
            ));
        } else {
            setDisplayStudents(students)
        }
    }
    return (
        <div>
            <Toaster />
            <div className="rd-dashboard-content">
                <DashboardHeader />
                <main className="rd-site-content">
                    <div className="rd-site-content__inner">
                        <Header />
                        <div className="rd-page-content" data-scrollbar>
                            <div className="rd-content-area">
                                <div className="rd-customer-heading">
                                    <div className="rd-heading-filter">
                                        <h1 className="rd-page-title">Users</h1>
                                        <form className="rd-search-form">
                                            <input type="text" className="form-control" placeholder="search users" onChange={handleSearch} />
                                            <button type="submit" className="btn btn-icon">
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_397_15704)">
                                                        <path d="M20.8718 19.6345L14.7715 13.5342C15.9285 12.1057 16.625 10.2897 16.625 8.31253C16.625 3.72903 12.896 0 8.31249 0C3.72898 0 0 3.72903 0 8.31253C0 12.896 3.72903 16.6251 8.31253 16.6251C10.2897 16.6251 12.1057 15.9285 13.5342 14.7715L19.6346 20.8719C19.8055 21.0427 20.0824 21.0427 20.2533 20.8719L20.8719 20.2533C21.0427 20.0824 21.0427 19.8054 20.8718 19.6345ZM8.31253 14.875C4.69376 14.875 1.75002 11.9313 1.75002 8.31253C1.75002 4.69376 4.69376 1.75002 8.31253 1.75002C11.9313 1.75002 14.875 4.69376 14.875 8.31253C14.875 11.9313 11.9313 14.875 8.31253 14.875Z" fill="#7C7C7C" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_397_15704">
                                                            <rect width="21" height="21" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>
                                        </form>
                                    </div>
                                    <div className="rd-heading-btn">
                                        <Link to="/invite-user" className="btn btn-dark">Invite User</Link>
                                    </div>
                                </div>
                                <div className="rd-customers-table">
                                    <div className="table-responsive" data-scrollbar>
                                        <table className="table table-borderless table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">Staff/Student Name</th>
                                                    {/* <th scope="col">Staff/Student <br /> Department</th> */}
                                                    <th scope="col">Email ID </th>
                                                    <th scope="col">Invite Status</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {spinner ? <tr><td colSpan={5}><Spinner /></td></tr>
                                                :
                                                <tbody>
                                                    {currentStudents.length > 0 ? currentStudents.map((elem, index) =>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{elem.staff_student_name}</td>
                                                            {/* <td>{elem.staff_student_dept_class}</td> */}
                                                            <td>{elem.staff_student_email}</td>
                                                            {elem.invited_status === 0 ?
                                                                <>
                                                                    <td><span className="rd-tags rd-tag-info">Pending</span></td>
                                                                    <td><span className="om-dashboard-panding" style={{ cursor: 'pointer' }} onClick={() => reInviteUser(elem._id)}>Reinvite</span></td>
                                                                </>
                                                                : <>
                                                                    <td><span className="rd-tags rd-tag-warning">Accepted</span></td>
                                                                    <td></td>
                                                                </>}
                                                            <td className="ps-0">
                                                                <div className="shipping-table-edit">
                                                                    <Link to='/edit-user' state={elem} className="btn shopping-table-success"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2 17H3.4L12.025 8.375L10.625 6.975L2 15.6V17ZM16.3 6.925L12.05 2.725L13.45 1.325C13.8333 0.941667 14.3043 0.75 14.863 0.75C15.421 0.75 15.8917 0.941667 16.275 1.325L17.675 2.725C18.0583 3.10833 18.2583 3.571 18.275 4.113C18.2917 4.65433 18.1083 5.11667 17.725 5.5L16.3 6.925ZM14.85 8.4L4.25 19H0V14.75L10.6 4.15L14.85 8.4Z" fill="white" />
                                                                    </svg>
                                                                    </Link>
                                                                    <a href="" onClick={(e) => handleDelete(e, elem._id)} className="btn shopping-table-danger" data-bs-toggle="modal" data-bs-target="#deleteModal"><svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="white" />
                                                                    </svg>
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) :
                                                        <tr>
                                                            <td colSpan={5}><strong>No User Found</strong></td>
                                                        </tr>}
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {currentStudents.lenght > 0 ?
                                <div class="rd-table-pagination">
                                    {currentPage !== 1 ? <a class="rd-table-pagination-arrow" onClick={() => paginate(currentPage - 1)}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_167_1136)">
                                                <path
                                                    d="M5.02568 11.2496L14.4693 20.69C14.8837 21.1034 15.555 21.1034 15.9704 20.69C16.3848 20.2767 16.3848 19.6053 15.9704 19.192L7.2759 10.5006L15.9694 1.80919C16.3838 1.39586 16.3838 0.724501 15.9694 0.310122C15.555 -0.103211 14.8826 -0.103211 14.4682 0.310122L5.02463 9.7505C4.61662 10.1596 4.61662 10.8415 5.02568 11.2496Z"
                                                    fill="#2D2D2D" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_167_1136">
                                                    <rect width="21" height="21" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a> : ""}
                                    <p class="rd-table-pagination-text">page {currentPage} of {pageNumbers.length}</p>
                                    {currentPage !== pageNumbers[pageNumbers.length - 1] ? <a class="rd-table-pagination-arrow" onClick={() => paginate(currentPage + 1)}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_167_1133)">
                                                <path
                                                    d="M15.9743 11.2496L6.53072 20.69C6.11634 21.1034 5.44498 21.1034 5.02955 20.69C4.61517 20.2767 4.61517 19.6053 5.02955 19.192L13.7241 10.5006L5.0306 1.80919C4.61622 1.39586 4.61622 0.724501 5.0306 0.310122C5.44498
                            -0.103211 6.11739 -0.103211 6.53176 0.310122L15.9754 9.7505C16.3834 10.1596 16.3834 10.8415 15.9743 11.2496Z"
                                                    fill="#2D2D2D" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_167_1133">
                                                    <rect width="21" height="21" fill="white" transform="matrix(-1 0 0 1 21 0)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a> : ''}
                                </div> : ''}
                        </div>
                    </div>
                </main>
            </div>

        </div>
    )
}

export default Dashboard