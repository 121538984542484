import React, { useState } from 'react'
import DashboardHeader from './DashboardHeader'
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
import axios from 'axios'
import { OM_API_URL } from '../BaseURL'
import { toast, Toaster } from 'react-hot-toast';

const InviteUser = () => {
    const [details, setDetails] = useState({
        name: '',
        class: '',
        email: '',
        message: ''
    });
    const [error, setError] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!details.name) {
            isValid = false;
            err['name_err'] = "Please enter name"
        }

        // if (!details.class) {
        //     isValid = false;
        //     err['class_err'] = "Please enter department/class"
        // }

        if (!details.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        } else if (typeof details.email !== "undefined") {
            let lastAtPos = details.email.lastIndexOf('@');
            let lastDotPos = details.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && details.email.indexOf('@@') === -1 && lastDotPos > 2 && (details.email.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Email is not valid";
            }
        }

        if (!details.message) {
            isValid = false;
            err['message_err'] = "Please enter message"
        }

        setError(err);
        return isValid
    }

    const handleInvite = (e) => {
        e.preventDefault();
        if (validation()) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('name', details.name);
            bodyFormData.append('email', details.email);
            bodyFormData.append('message', details.message);
            bodyFormData.append('department_class', details.class);
            axios({
                method: 'post',
                url: `${OM_API_URL}/web/invite-staff-student`,
                data: bodyFormData,
                headers: { 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN')}` }
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    toast.success('User invited', { style: { background: '#333', color: '#fff' } });
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, [1000])
                }
            }).catch((error) => {
                toast.error(error.response.data.message, { style: { background: '#333', color: '#fff' } })
            })
        }

    }

    return (
        <div>
            <Toaster />
            <div class="rd-dashboard-content">
                <DashboardHeader />
                <main class="rd-site-content">
                    <div class="rd-site-content__inner">
                        <Header />
                        <div class="rd-page-content" data-scrollbar>
                            <div class="rd-page-beardcrumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item">
                                            <Link to="/dashboard">
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_397_16113" style={{ masktype: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                                                        <rect x="30" width="30" height="30" transform="rotate(90 30 0)" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_397_16113)">
                                                        <path d="M10.7817 15L18.2817 7.5L20.0317 9.25L14.2817 15L20.0317 20.75L18.2817 22.5L10.7817 15Z" fill="black" />
                                                    </g>
                                                </svg>
                                                <span>Invite User</span>
                                            </Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="row">
                                <div class="col col-12 col-lg-7">
                                    <div class="rd-content-area rd-invite-user">
                                        <div class="row">
                                            <div class="col col-12">
                                                <label class="form-label">Name</label>
                                                <input type="text" class={error.name_err ? "form-control " : "form-control"} name="name" onChange={handleChange} value={details.name} />
                                                <span className='error'>{error.name_err}</span>
                                            </div>
                                            {/* <div class="col col-12">
                                                <label class="form-label">Department/Class</label>
                                                <input type="text" class={error.class_err ? "form-control error" : "form-control"} name="class" onChange={handleChange} value={details.class} />
                                                <span className='error'>{error.class_err}</span>
                                            </div> */}
                                            <div class="col col-12">
                                                <label class="form-label">Email ID</label>
                                                <input type="text" class={error.email_err ? "form-control " : "form-control"} name="email" onChange={handleChange} value={details.email} />
                                                <span className='error'>{error.email_err}</span>
                                            </div>
                                            <div class="col col-12">
                                                <label class="form-label">Message</label>
                                                <textarea class={error.message_err ? "form-control " : "form-control"} cols="30" rows="3" name="message" onChange={handleChange} value={details.message}></textarea>
                                                <span className='error'>{error.message_err}</span>
                                            </div>
                                        </div>
                                        <div class="rd-heading-btn rd-invite-btn">
                                            <button type='button' onClick={handleInvite} class="btn btn-dark">Invite</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default InviteUser