import React, { useEffect, useState } from "react";
import axios from 'axios';

import {
    useStripe, useElements,
    CardNumberElement, CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import { OM_API_URL } from "../BaseURL";
import { Link, useNavigate } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                lineHeight: "27px",
                color: "#A5A5A5",
                "::placeholder": {
                    color: "#AAB7C4",
                },
                backgroundColor: '#FFFFFF',
            },
            invalid: {
                color: "#FA755A",
                iconColor: "#FA755A",
            },
        },
    };

    const [name, setName] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({});
    const nav = useNavigate();


    const HandleCard = async () => {
        if (name) {
            setError({ ...error, name_err: null })


            const { err, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name
                },
            })

            if (!err) {
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('payment_method_id', paymentMethod.id);
                bodyFormData.append('plan_id', props.plan._id);
                bodyFormData.append('amount', props.plan.amount);
                bodyFormData.append('plan_duration', props.plan.duration);
                setIsLoading(true);
                axios({
                    method: 'post',
                    url: `${OM_API_URL}/web/start-subscription`,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN')}` }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data?.status === true) {

                        toast.success(response.data?.message, { style: { background: '#333', color: '#fff' } });
                        setTimeout(() => {
                            nav('/dashboard');
                        }, [1000])
                    } else {
                        toast.error(response.data.message, { style: { background: '#333', color: '#fff' } })
                    }
                    setIsLoading(false)
                }).catch((err) => {
                    setError({ ...error, password_err: err.response.data.message })
                    toast.error(error.response.data.message, { style: { background: '#333', color: '#fff' } })
                    setIsLoading(false)
                })
            } else {
                setError({ ...error, name_err: null, card_err: err })
            }
        }
        else {
            setError({ ...error, name_err: "Enter Name" })

        }


    }
    const cardElementHandler = (e, type) => {
        if (type == "name_err") {
            console.log(name);
            if (name == "" || name == null)
                setError({ ...error, [type]: null })
            setError({ ...error, [type]: "Enter Name" })
        }
        if (e.error) {
            setError({ ...error, [type]: e.error.message })
            console.log(error)
            //displayError.textContent = e.error.message;
        } else {
            setError({ ...error, [type]: null })

        }
    };
    return (

        <form id="payment-form">
            <Toaster />
            <h4 className="om-payment-title">Add New Credit Card</h4>
            <div className="manage-account-card">
                <Link to="#"><img src="assets/images/visa.png" alt="" className="img-fluid" /></Link>
                <Link to="#"><img src="assets/images/mastercard.png" alt="" className="img-fluid" /></Link>
                <Link to="#"><img src="assets/images/amex.png" alt="" className="img-fluid" /></Link>
                <Link to="#"><img src="assets/images/discover.png" alt="" className="img-fluid" /></Link>
            </div>
            <div className="rd-dashboard-form rd-manage-account-form">
                <form>
                    <div className="rd-customer-info-form subscription-input">
                        <div className="row">
                            <div className="col col-12">
                                <label for="inputCompany" className="form-label">Credit Card Holder</label>
                                {/* <input type="text" className="form-control" id="inputBillingAddress" /> */}
                                <input type="text" className={error.name_err ? "form-control error" : "form-control"} onChange={(e) => { setName(e.target.value); cardElementHandler(e, "name_err") }} />
                                <span className='error'>{error.name_err}</span>
                            </div>
                            <div className="col col-12">
                                <label for="inputCompany" className="form-label">Credit Card Number</label>
                                {/* <input type="text" className="form-control" id="inputBillingAddress" /> */}
                                <CardNumberElement
                                    id="cc-number"
                                    className={error.card_number_err ? "form-control error" : "form-control"}
                                    options={CARD_ELEMENT_OPTIONS} onChange={(e) => cardElementHandler(e, "card_number_err")}
                                />
                                <span className='error'>{error.card_number_err}</span>

                            </div>
                            <div className="col col-12 col-md-6">
                                <label for="inputCompany" className="form-label">Expiration</label>
                                <CardExpiryElement
                                    id="expiry"
                                    className={error.card_number_err ? "form-control error" : "form-control"}
                                    options={CARD_ELEMENT_OPTIONS} onChange={(e) => cardElementHandler(e, "card_exp_err")}
                                />
                                <span className='error'>{error.card_exp_err}</span>
                            </div>

                            <div className="col col-12 col-md-6">
                                <label for="inputCompany" className="form-label">CVV</label>
                                <CardCvcElement
                                    id="cvc"
                                    className={error.card_cvv_err ? "form-control error" : "form-control"}
                                    options={CARD_ELEMENT_OPTIONS} onChange={(e) => cardElementHandler(e, "card_cvv_err")}
                                />
                                <span className='error'>{error.card_cvv_err}</span>
                            </div>

                            <span className='error'>{error.card_err}</span>
                        </div>
                    </div>
                </form>
            </div>
            <div className="rd-subscription-btn om-payment-btn">
                <button type="button" className="btn btn-dark" onClick={HandleCard} disabled={isLoading}>Save & Pay</button>
            </div>
        </form>
    );
}